import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, Link } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Chat from './components/Chat';
import Login from './components/Login';
import Signup from './components/Signup';
import LandingPage from './components/LandingPage';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';
import About from './components/About';
import Pricing from './components/Pricing';
import routes from './routes';

import ReactGA from 'react-ga4';
import { initFacebookPixel, trackFacebookEvent, getFallbackPixelUrl } from './utils/facebookPixel';

// Your Google Analytics Measurement ID
const MEASUREMENT_ID = "G-SYM1MR3BTG";
const PIXEL_ID = "1659452907950402";   // Meta Pixel ID

function App() {
  useEffect(() => {
    ReactGA.initialize(MEASUREMENT_ID);
    initFacebookPixel(PIXEL_ID);
  }, []);

  return (
    <AuthProvider>
      <noscript>
        <img 
          height="1" 
          width="1" 
          style={{ display: 'none' }}
          src={getFallbackPixelUrl(PIXEL_ID)}
          alt=""
        />
      </noscript>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isCookiePresent, isLoading } = useAuth();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    trackFacebookEvent('PageView');
  }, [location]);

  useEffect(() => {
    if (isCookiePresent) {
      navigate(routes.chat);
    }
  }, [isCookiePresent, navigate]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <AppBar 
        position="static" 
        sx={{ 
          backgroundColor: '#f1f0e8',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
        }}
      >
        <Toolbar sx={{ justifyContent: 'center' }}>
          {/* Logo and Home link */}
          <Button
            component={RouterLink}
            to={routes.home}
            sx={{
              color: '#3C392B',
              fontSize: '1.2rem',
              textTransform: 'none',
              mr: 2
            }}
          >
            Home
          </Button>

          {/* About link */}
          <Button
            component={RouterLink}
            to={routes.about}
            sx={{
              color: '#3C392B',
              fontSize: '1.2rem',
              textTransform: 'none',
              mr: 2
            }}
          >
            About
          </Button>

          {/* Pricing link */}
          {/* <Button
            component={RouterLink}
            to={routes.pricing}
            sx={{
              color: '#3C392B',
              fontSize: '1.2rem',
              textTransform: 'none'
            }}
          >
            Pricing
          </Button> */}
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path={routes.home} element={<LandingPage />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.chat} element={<Chat />} />
        <Route path={routes.terms} element={<Terms />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={routes.about} element={<About />} />
        <Route path={routes.pricing} element={<Pricing />} />
      </Routes>
    </>
  );
}

export default App;
